<template>
  <v-footer id="home-footer" color="#002773" dark min-height="72">
    <v-row align="center" justify="center">
      <v-btn
        v-for="(link, i) in links"
        :key="i"
        v-bind="link"
        text
        rounded
        class="my-2"
        @click="onMenuClick($event, link)"
      >
        {{ $t(link.text) }}
      </v-btn>
      <!-- NID -->
      <v-btn
        href="https://www.nid.co.jp"
        target="_blank"
        rel="noopener noreferrer"
        text
        rounded
      >
        {{ $t("menu.company") }}
      </v-btn>
      <!-- Contact -->
      <v-btn v-bind="email_address" text rounded>
        {{ $t("menu.contact") }}
      </v-btn>
      <v-col class="text-center text-md-right" cols="12" md="6">
        Copyright &copy; {{ new Date().getFullYear() }} NID Co., Ltd. All Rights
        Reserved.
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CoreFooter",
  data: () => ({
    email_address: {
      href: "mailto:" + process.env.VUE_APP_EMAIL,
    },
  }),
  computed: {
    ...mapGetters(["links"]),
  },
  methods: {
    // メニュー選択
    onMenuClick(e, item) {
      e.stopPropagation();

      if (item.to || !item.href) return;

      this.$vuetify.goTo(item.href.endsWith("#") ? 0 : item.href);
    },
  },
};
</script>

<style lang="sass">
#home-footer a
  text-decoration: none
</style>
